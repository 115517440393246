(function($, ns) {
  "use strict";
  $(function() {
    initComponents($('[data-component="syte.banner-game"]'), ns.BannerGame);
    initComponents($('[data-component="syte.sidebar-team"]'), ns.SidebarTeam);
    initComponents($('[data-component="syte.series"]'), ns.Series);
    // initComponents($('[data-component="syte.google-maps"]'), ns.GoogleMaps);
  });

  function initComponents(components, componentName) {
    if (components.length) {
      for (let i = 0; i != components.length; i++) {
        new componentName($(components[i]));
      }
    }
  }
  window.Syte = ns;
})(window.jQuery, window.Syte || {});
